export default {
  text: '#47535a',
  text2: '#47535a',
  primary: '#1c445d',
  secondary: '#3591d0',
  background: '#ffffff',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#a9a98f',
  backgroundTertiary: '#f28076',
  highlight: 'f28076',
  light: '#FFF',
  dark: '#555555'
}
