export default {
  heading: 'All Round Gothic Bold',
  subheading: 'Comfortaa, sans-serif',
  body: 'Mundo Sans Std, sans-serif',
  body2: 'Comfortaa, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: ['Open Sans:100,200,300,400,500,600,700,800,900', 'Comfortaa'],
  customFamilies: ['All Round Gothic Bold', 'Futura', 'Mundo Sans Std'],
  customUrls: [
    'https://www.gonation.biz/fonts/all-round-gothic-bold/all-round-gothic-bold.css',
    'https://www.gonation.biz/fonts/futura/futura.css',
    'https://www.gonation.biz/fonts/mundo-sans-std/mundo-sans-std.css'
  ]
}
