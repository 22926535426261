export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      height: 'auto',
      padding: '0rem',
      margin: '0rem',
      position: 'static',
      mr: 'auto'
    },
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['30px', '40px', '40px'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['30px', '40px', '40px'],
        filter: 'unset',
        padding: '0rem'
      }
    },

    '.container': {
      padding: ['1rem', '1rem', '1rem', '1rem'],
      position: 'static',
      backgroundColor: 'transparent',
      top: '0rem',
      justifyContent: 'flex-start'
    },
    '.containerScrolled': {
      padding: ['1rem', '1rem', '1rem', '1rem'],
      backgroundColor: 'background',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'primary',
      '.phoneContainer': {
        display: 'none'
      }
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', '', 'none'],
      mr: '0rem',
      '> div': {
        backgroundColor: 'primary'
      }
    },
    '.smallNavMenu': {
      alignItems: 'center',
      '.navItem': {
        borderLeft: '1px solid',
        borderColor: 'dark',
        padding: '0rem 0.25rem 0rem 0.75rem',
        mr: '0rem',
        a: { fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem', '0.9rem'] },
        color: 'text',
        ':hover': {
          color: 'primary'
        }
      },
      '> div :nth-of-type(1)': {
        border: 'none'
      },
      '.order-now': {
        backgroundColor: 'backgroundTertiary',
        // backgroundColor: 'transparent',
        color: 'white',
        padding: '0.75rem 1.5rem',
        border: 'solid 1px',
        borderColor: 'dark',
        ml: '2rem',
        cursor: 'pointer',
        ':hover': {
          color: 'secondary',
          a: { color: 'primary' },
          // backgroundColor: 'primary',
          borderColor: 'primary',
          backgroundColor: 'secondary'
        }
      },
      '.delivery': {
        backgroundColor: 'primary',
        padding: '0.75rem 1.5rem',
        color: 'white',
        border: 'solid 1px',
        borderColor: 'primary',
        ':hover': {
          color: 'primary',
          a: { color: 'primary' },
          backgroundColor: 'transparent',
          borderColor: 'primary'
        }
      }
    },

    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenu': {
      transform: 'unset',
      top: '0rem',
      transition: 'all ease-in-out 0.5s',
      height: '100vh',
      left: '-200vw'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      textAlign: ['', '', '', 'right'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          fontFamily: 'heading'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white',
        fontFamily: 'heading'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },
    '.navBlock': {
      right: '100%',
      top: '-200vh'
    }
  },

  footer: {
    backgroundColor: '#C3B7A7',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['2rem 0.5rem 6rem', '', '2rem 3rem'],
    '.image': {
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        fontSize: '1.1rem',
        fontFamily: 'heading'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'white',
      textAlign: 'center'
    },
    '.poweredByText': {
      margin: 'auto'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',

      a: {
        padding: '0.5rem',
        border: 'solid 1px white',
        borderRadius: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          backgroundColor: 'dark'
        },
        svg: {
          width: '30px',
          height: '30px'
        }
      }
    },
    '.gonationLogo': {
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.4rem', '1.5rem'],
    fontWeight: 'light',
    letterSpacing: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    fontFamily: 'subheading',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.75',
    fontSize: ['1rem', '', '1.1rem'],
    padding: '0.25rem 0rem',
    color: 'inherit',
    '*': {
      color: 'inherit',
      lineHeight: '2',
      textAlign: 'left',
      fontSize: ['1rem', '', '1.1rem']
    },
    p: {}
  },

  sideBySide1: {
    padding: ['1rem', '', '3rem'],
    backgroundColor: 'background',
    margin: '0rem',
    '.lazyload-wrapper': {
      justifyContent: 'flex-start',
      width: ['', '', '40%', '40%']
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      width: ['', '', '60%', '60%'],
      padding: ['', '', '5vw']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '3.5rem', '5.5rem', '6.5rem', '7.5rem'],
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2'
    },
    '.text': {
      variant: 'customVariants.text',
      order: '3'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    overflow: 'hidden',
    height: 'calc(100vh - 78px)',
    '.slick-initialized .slick-slide > div, .slick-slider, .slick-list, .slick-track, .slick-slide > div, .slick-slide img':
      {
        height: 'calc(100vh - 78px)'
      },

    '.hero_content_container': {
      position: 'absolute',
      top: '50%',
      left: ['0%', '', '', '50%'],
      position: 'absolute',
      // transform: 'translate(-50%,-50%)',
      maxWidth: '767px',
      margin: '0rem',
      width: ['100%', '', '', 'fit-content'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-start'],
      maxWidth: 'unset',
      '.linksContainer': {
        justifyContent: ['center', '', '', 'flex-start']
      }
    },
    color: 'white',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '7.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.title',
      // variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem', '3.5rem'],
      opacity: '1',
      color: 'white',
      fontFamily: 'heading'
    },
    '.text': {
      variant: 'customVariants.text',
      '*': {
        color: 'white',
        fontFamily: 'heading',
        fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem', '3.5rem']
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      backgroundColor: 'backgroundTertiary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },
  homepageShout: {
    backgroundColor: 'background',
    marginBottom: ['5rem', '', '0rem'],
    marginTop: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 6rem', '3rem 3rem 6rem'],
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '3px solid',
      borderColor: 'text2',
      fontFamily: 'heading',
      width: ['', '', '70%'],
      backgroundColor: 'transparent',
      letterSpacing: '0px',
      color: 'text',
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem', '1.3rem']
    },
    '.text': {
      variant: 'customVariants.text',
      fontFamily: 'heading',
      fontSize: ['1.25rem', '', ''],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['backgroundSecondary'],
      padding: ['2rem', '2rem'],
      marginTop: ['', '0rem', '', '3rem'],
      width: ['', '', '45%', '45%', '55%']
    },
    '.date': {
      backgroundColor: ['transparent', '', 'primary'],
      border: ['solid 1px', '', '', 'solid 0.5rem'],
      borderColor: ['dark', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: ['dark', '', 'white'],
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'center',
      position: 'relative',
      left: ['', '', '', '-3rem'],
      bottom: ['', '', '', '-2rem']
    },
    '.imageContainer': {
      position: 'relative',
      mt: ['0.5rem', '', '0rem', '', ''],
      top: ['', '', '', '2.5rem'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1',
      '.imageFill': {
        paddingBottom: ['100%', '70%', '70%']
      }
    },
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      order: ['6', '', '5', '5'],
      // marginLeft: ['0rem', '', '0rem'],
      a: {
        width: ['fit-content', '', '', 'fit-content'],
        flexGrow: 'unset',
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: ['1rem 3.5rem', '', '', '3.5rem 1rem'],
        backgroundColor: 'transparent',
        color: 'grey',
        borderColor: 'grey',
        fontFamily: 'sans-serif',
        fontWeight: 'normal',
        letterSpacing: '0px',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white',
          borderColor: 'primary'
        }
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/sites/freebird/bird-prints.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: 'contain',
    backgroundAttachment: ['', '', '', 'unset'],
    padding: ['0rem', '0rem', '0rem'],
    '.section': {
      backgroundColor: ['#ffffffde', '', '', '', 'unset'],
      width: ['', '', '100%'],
      padding: ['2rem', '2rem', '3rem', '4rem', '5rem'],
      margin: '0rem',
      maxWidth: ' 1200px',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  homepageAboutSide: {
    variant: 'customVariants.sideBySide1',
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/sites/freebird/bird-prints.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    backgroundSize: 'contain',
    backgroundAttachment: ['', '', '', 'unset'],
    padding: ['0rem', '0rem', '1rem'],
    display: 'flex',
    justifyContent: 'center!important',
    alignItems: 'center!important',
    flexDirection: 'column-reverse!important',
    '.content': {
      backgroundColor: 'white!important'
    },
    '.lazyload-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      img: {
        borderRadius: '100%',
        maxWidth: '400px'
      }
    },
    '.section': {
      backgroundColor: ['#ffffffde', '', '', '', 'unset'],
      width: ['', '', '100%'],
      padding: ['2rem', '2rem', '3rem', '4rem', '5rem'],
      margin: '0rem',
      maxWidth: ' 1200px',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },

  homepageAbout2: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'primary',
    color: 'white',
    '.ctaButton': {
      variant: 'buttons.primary',
      color: 'white',
      borderColor: 'white',
      ':hover': {
        backgroundColor: 'secondary',
        color: 'white',
        borderColor: 'secondary'
      }
    }
  },

  homepageGiftCards: {
    variant: 'customVariants.sideBySide1',
    padding: ['2rem 1rem', '2rem 2rem'],
    minHeight: '70vh',
    'div.content': {
      order: ['2', '', 'unset']
    },
    '.lazyload-wrapper': {
      overflow: 'hidden',
      position: 'relative',
      '::before': {
        content: "'$50'",
        color: 'white',
        opacity: '0.8',
        position: 'absolute',
        bottom: '-2%',
        right: '-2%',
        backgroundColor: 'secondary',
        borderRadius: '500px 500px 0px 500px',
        padding: '1rem',
        fontWeight: 'bold'
      },
      margin: ['1rem', '', 'auto'],
      border: 'solid 1px',
      borderRadius: '10px',
      height: ['150px', '180px'],
      width: ['290px', '320px'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'primary',
      img: {
        objectFit: 'contain',
        maxHeight: '80px'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    minHeight: '70vh',
    backgroundColor: 'backgroundTertiary',
    '.ctaButton': {
      variant: 'buttons.primary',
      color: 'white',
      borderColor: 'white',
      ':hover': {
        backgroundColor: 'secondary',
        color: 'white',
        borderColor: 'secondary'
      }
    }
  },
  homepageCatering: {
    variant: 'customVariants.sideBySide1',
    minHeight: '70vh'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'secondary',
    minHeight: '70vh',
    color: 'white',
    '.ctaButton': {
      variant: 'buttons.primary',
      color: 'white',
      borderColor: 'white',
      ':hover': {
        backgroundColor: 'secondary',
        color: 'white',
        borderColor: 'secondary'
      }
    }
  },
  homepageOrderOnline: {
    variant: 'customVariants.sideBySide1',
    minHeight: ['', '', '30vh', '40vh', '40vh', '40vh'],
    color: 'white',
    backgroundAttachment: ['', '', 'unset'],
    '.section': {
      margin: 'unset',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      textAlign: 'left',
      padding: '10vw'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      color: 'white',
      borderColor: 'white'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: '20vh 2rem 20vh',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.text': {
      lineHeight: '2.5'
    }
  },

  AboutOwners: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 5rem',
    height: ['unset', 'unset', 'unset', 'unset', 'unset'],
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      padding: ['', '', '2rem 3rem'],
      width: ['', '', '40%'],
      img: {
        objectFit: 'cover',
        objectPosition: 'top'
      }
    }
  },

  // ? =======================
  // ? =====  Menu page  =====
  // ? =======================

  menuCTAS: {
    padding: '3rem 1rem 0rem',
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      margin: '0 auto'
    }
  },

  menu: {
    '.menuTab': {
      fontFamily: 'body',
      variant: 'buttons.primary',
      borderRadius: '500px',
      fontFamily: 'body',
      textTransform: 'uppercase'
    },
    '.menuTabActive': {
      backgroundColor: 'primary',
      borderColor: 'primary',
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Partners page  ====
  // ? ========================

  ourPartners: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    background: 'none',
    backgroundColor: '#0a537dd1',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: '#72aee7',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'light',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem',
      justifyContent: 'flex-start'
    },

    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: '#c3b7a7',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem'],
      alignItems: 'center',
      color: 'white',
      marginBottom: '0rem !important'
    },
    '.contactDetails-container': {
      padding: '1rem 1rem 2rem',
      '.text': {
        fontSize: '0.9rem'
      }
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '1rem 5rem'],
        '.dayContainer': {
          mb: '0.5rem'
        },
        '.dayofWeekText': {
          mb: '0rem',
          border: 'none'
        },
        '.timeBlocks': {
          justifyContent: 'flex-start'
        }
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      textAign: 'left',
      margin: '1rem',
      borderBottom: '2px solid',
      width: 'calc(100% - 2rem)',
      fontSize: ['2rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      justifyContent: 'flex-start'
    },

    '.boxesContainer': {
      justifyContent: 'flex-start'
    },
    '.box': {
      flexGrow: 1,
      maxWidth: '360px'
    },
    '.textContent': {
      backgroundColor: 'white',
      '.subtitle': {
        color: 'dark',
        fontSize: '1rem'
      }
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'cover',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ============================================
  // ? ====  Private Events and Catering page  ====
  // ? ============================================

  privateEventsIntro: {
    padding: '20vh 2rem 20vh',
    background: 'background',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem']
    },
    '.text': {
      p: {
        lineHeight: '2.5'
      }
    }
  }
}
